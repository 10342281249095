<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Viaje</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Internos</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Viaje</li>
                  <li class="breadcrumb-item active">Editar</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2 border-0">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Viaje {{ id }}</h5>
                </div>
              </div>
              <div class="card-body p-0">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li
                    class="nav-item"
                    v-if="$store.getters.can('tep.viajes.edit')"
                  >
                    <a
                      class="nav-link active"
                      id="tab-Detalles"
                      data-toggle="tab"
                      href="#Detalles"
                      >Detalles</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="$store.getters.can('tep.viajesInternos.editFechas')"
                  >
                    <a
                      class="nav-link"
                      id="tab-Fechas"
                      data-toggle="tab"
                      href="#Fechas"
                      >Fechas</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade active show"
                    id="Detalles"
                    v-if="$store.getters.can('tep.viajesInternos.editFechas')"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-5">
                          <label>Transportadora</label>
                          <v-select
                            :class="[
                              $v.form.detalles.transportadora.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.detalles.transportadora"
                            placeholder="Transportadora"
                            label="razon_social"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.transportadoras"
                            :filterable="true"
                            @input="changeEmpresa"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-5">
                          <label>Ruta</label>
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="form.detalles.ruta"
                            placeholder="Ruta"
                            label="nombre"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.rutas"
                            :filterable="true"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-2">
                          <label>Vehículo</label>
                          <v-select
                            :class="[
                              $v.form.detalles.vehiculo.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.detalles.vehiculo"
                            placeholder="Vehículo"
                            label="placa"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.vehiculos"
                            :filterable="true"
                          ></v-select>
                        </div>
                        <div
                          class="form-group col-md-6"
                          v-if="
                            $route.params.data_viaje.solicitudes
                              .tipo_operacion != 3
                          "
                        >
                          <label>Conductor</label>
                          <v-select
                            :class="[
                              $v.form.detalles.conductor.$invalid
                                ? 'is-invalid'
                                : 'is-valid',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="form.detalles.conductor"
                            placeholder="Conductor"
                            label="nConductor"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.conductores"
                            :filterable="true"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Dirección Origen</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.detalles.direccion_origen"
                            :class="
                              $v.form.detalles.direccion_origen.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label>Dirección Destino</label>
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            v-model="form.detalles.direccion_destino"
                            :class="
                              $v.form.detalles.direccion_destino.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label>Fecha Inicio Servicio Usuario</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.detalles.fecha_ini_app_user"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_ini_app_user.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioUsuario"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.detalles.hora_ini_app_user"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_ini_app_user.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioUsuario"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-3">
                          <label>Fecha Fin Servicio Usuario</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.detalles.fecha_fin_app_user"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_fin_app_user.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioUsuario"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.detalles.hora_fin_app_user"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_fin_app_user.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioUsuario"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group col-md-3"
                          v-if="
                            $route.params.data_viaje.solicitudes
                              .tipo_operacion != 3
                          "
                        >
                          <label>Fecha Inicio Servicio Conductor</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.detalles.fecha_ini_app_conductor"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_ini_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioConductor"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.detalles.hora_ini_app_conductor"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_ini_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioConductor"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group col-md-3"
                          v-if="
                            $route.params.data_viaje.solicitudes
                              .tipo_operacion != 3
                          "
                        >
                          <label>Fecha Fin Servicio Conductor</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.detalles.fecha_fin_app_conductor"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_fin_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioConductor"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.detalles.hora_fin_app_conductor"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_fin_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasServicioConductor"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          class="form-group col-md-3"
                          v-if="
                            $route.params.data_viaje.solicitudes
                              .tipo_operacion != 3
                          "
                        >
                          <label>Llegada Conductor</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="
                                  form.detalles.fecha_llegada_app_conductor
                                "
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.fecha_llegada_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="
                                  form.detalles.hora_llegada_app_conductor
                                "
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.detalles.hora_llegada_app_conductor
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-12 text-right">
                          <button
                            class="btn bg-success"
                            v-if="
                              !$v.form.detalles.$invalid &&
                                $store.getters.can('tep.viajes.edit')
                            "
                            @click="save('detalles')"
                          >
                            <i class="fas fa-save"></i> Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="Fechas"
                    v-if="$store.getters.can('tep.viajesInternos.editFechas')"
                  >
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-5">
                          <label for="fecha_ini">Fecha y Hora Inicial</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.fechas.fecha_ini"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fechas.fecha_ini.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasViaje"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.fechas.hora_ini"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fechas.hora_ini.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasViaje"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-5">
                          <label for="fecha_fin">Fecha y Hora Final</label>
                          <div class="row">
                            <div class="col-md-7">
                              <input
                                type="date"
                                v-model="form.fechas.fecha_fin"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fechas.fecha_fin.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasViaje"
                              />
                            </div>
                            <div class="col-md-5">
                              <input
                                v-model="form.fechas.hora_fin"
                                type="text"
                                v-mask="{
                                  mask: '99:99:99',
                                  hourFormat: '24',
                                }"
                                class="form-control form-control-sm"
                                :class="
                                  $v.form.fechas.hora_fin.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                                @blur="validaFechasViaje"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-2">
                          <button
                            class="btn bg-success mt-4"
                            v-if="
                              !$v.form.fechas.$invalid &&
                                $store.getters.can(
                                  'tep.viajesInternos.editFechas'
                                )
                            "
                            @click="save('fechas')"
                          >
                            <i class="fas fa-save"></i> Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, email } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";

export default {
  name: "TepViajeInternoEdit",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      id: null,
      accion: null,
      viaje: null,
      form: {
        detalles: [],
        fechas: [],
      },
      listasForms: {
        transportadoras: [],
        conductores: [],
        vehiculos: [],
        rutas: [],
      },
    };
  },
  validations() {
    if (this.$route.params.data_viaje.solicitudes.tipo_operacion != 3) {
      return {
        form: {
          detalles: {
            transportadora: { required },
            conductor: { required },
            vehiculo: { required },
            direccion_origen: { required },
            direccion_destino: { required },
            fecha_ini_app_user: { required },
            hora_ini_app_user: { required },
            fecha_fin_app_user: { required },
            hora_fin_app_user: { required },
            fecha_ini_app_conductor: { required },
            hora_ini_app_conductor: { required },
            fecha_fin_app_conductor: { required },
            hora_fin_app_conductor: { required },
            fecha_llegada_app_conductor: { required },
            hora_llegada_app_conductor: { required },
          },
          fechas: {
            fecha_ini: { required },
            hora_ini: { required },
            fecha_fin: { required },
            hora_fin: { required },
          },
        },
      };
    } else {
      return {
        form: {
          detalles: {
            transportadora: { required },
            vehiculo: { required },
            direccion_origen: { required },
            direccion_destino: { required },
            fecha_ini_app_user: { required },
            hora_ini_app_user: { required },
            fecha_fin_app_user: { required },
            hora_fin_app_user: { required },
          },
          fechas: {
            fecha_ini: { required },
            hora_ini: { required },
            fecha_fin: { required },
            hora_fin: { required },
          },
        },
      };
    }
  },
  methods: {
    init() {
      this.id = this.$route.params.id;
      this.accion = this.$route.params.accion;
      axios
        .get("/api/tep/viajes/edit", {
          params: { id: this.id },
        })
        .then((response) => {
          this.viaje = response.data;

          // Métodos para llenar los formularios
          this.getFormData();
          this.cargando = false;
        });
    },

    async getEmpresas() {
      await axios.get("/api/admin/empresas/listaTep").then((response) => {
        this.listasForms.transportadoras = response.data;
        this.getRutas();
      });
    },

    async getConductores() {
      await axios
        .get("/api/admin/conductores/lista", {
          params: { linea_negocio_id: 5 },
        })
        .then((response) => {
          this.listasForms.conductores = response.data;
        });
    },

    async getVehiculos() {
      await axios
        .get("/api/admin/vehiculos/lista", {
          params: { linea_negocio_id: 5 },
        })
        .then((response) => {
          this.listasForms.vehiculos = response.data;
        });
    },

    changeEmpresa() {
      this.form.detalles.ruta = [];
      this.getRutas();
    },

    getRutas() {
      this.listasForms.rutas = [];
      axios
        .get("/api/tep/Ruta/lista", {
          params: { empresa_id: this.form.detalles.transportadora.id },
        })
        .then((response) => {
          this.listasForms.rutas = response.data;
        });
    },

    // Métodos para los formularios
    async getFormData() {
      // Formulario de detalles
      let ruta = null;
      if (this.viaje.ruta) {
        ruta = {
          id: this.viaje.ruta.id,
          nombre: this.viaje.ruta.nombre,
        };
      }

      this.form.detalles = {
        transportadora: {
          id: this.viaje.transportadora.id,
          razon_social: this.viaje.transportadora.razon_social,
        },
        ruta,
        conductor: {
          id: this.viaje.conductor ? this.viaje.conductor.id : null,
          nConductor: this.viaje.conductor
            ? `${this.viaje.conductor.numero_documento} - ${this.viaje.conductor.nombres} ${this.viaje.conductor.apellidos}`
            : null,
        },
        vehiculo: {
          id: this.viaje.vehiculo.id,
          placa: this.viaje.vehiculo.placa,
        },
        direccion_origen: this.viaje.direccion_origen,
        direccion_destino: this.viaje.direccion_destino,
        fecha_ini_app_user: this.viaje.tep_fechas_viajes[0].fecha_ini_app_user
          ? this.viaje.tep_fechas_viajes[0].fecha_ini_app_user.slice(0, 10)
          : "",
        hora_ini_app_user: this.viaje.tep_fechas_viajes[0].fecha_ini_app_user
          ? this.viaje.tep_fechas_viajes[0].fecha_ini_app_user.slice(11, 19)
          : "",
        fecha_fin_app_user: this.viaje.tep_fechas_viajes[0].fecha_fin_app_user
          ? this.viaje.tep_fechas_viajes[0].fecha_fin_app_user.slice(0, 10)
          : "",
        hora_fin_app_user: this.viaje.tep_fechas_viajes[0].fecha_fin_app_user
          ? this.viaje.tep_fechas_viajes[0].fecha_fin_app_user.slice(11, 19)
          : "",
        fecha_ini_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_ini_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_ini_app_conductor.slice(0, 10)
          : "",
        hora_ini_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_ini_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_ini_app_conductor.slice(
              11,
              19
            )
          : "",
        fecha_fin_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_fin_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_fin_app_conductor.slice(0, 10)
          : "",
        hora_fin_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_fin_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_fin_app_conductor.slice(
              11,
              19
            )
          : "",
        fecha_llegada_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_llegada_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_llegada_app_conductor.slice(
              0,
              10
            )
          : "",
        hora_llegada_app_conductor: this.viaje.tep_fechas_viajes[0]
          .fecha_llegada_app_conductor
          ? this.viaje.tep_fechas_viajes[0].fecha_llegada_app_conductor.slice(
              11,
              19
            )
          : "",
      };

      // Formulario de fechas
      this.form.fechas = {
        fecha_ini: this.viaje.fecha_ini
          ? this.viaje.fecha_ini.slice(0, 10)
          : "",
        hora_ini: this.viaje.fecha_ini
          ? this.viaje.fecha_ini.slice(11, 19)
          : "",
        fecha_fin: this.viaje.fecha_fin
          ? this.viaje.fecha_fin.slice(0, 10)
          : "",
        hora_fin: this.viaje.fecha_fin
          ? this.viaje.fecha_fin.slice(11, 19)
          : "",
      };

      // Funciones de llenado de listas para los formularios
      await this.getEmpresas();
      await this.getConductores();
      await this.getVehiculos();
    },

    save(tap) {
      this.cargando = true;
      let data = {};
      let data_form = null;
      let url = "/api/tep/viajes/saveInternos";

      switch (tap) {
        case "detalles":
          data_form = this.form.detalles;
          break;

        case "fechas":
          data_form = {
            fecha_ini: `${this.form.fechas.fecha_ini} ${this.form.fechas.hora_ini}`,
            fecha_fin: `${this.form.fechas.fecha_fin} ${this.form.fechas.hora_fin}`,
          };
          url = "/api/tep/viajes/saveFechasInternos";
          break;
      }

      if (data_form) {
        data = {
          id: this.id,
          tap,
          data_form,
        };
        let me = this;
        axios
          .post(url, data)
          .then((response) => {
            me.cargando = false;
            if (response.data.msg) {
              this.$swal({
                icon: "success",
                title: response.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            if (response.data.error) {
              me.$swal({
                icon: "error",
                title: "Ocurrió un error: " + response.data.error.errorInfo[2],
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.init();
          })
          .catch(function(error) {
            me.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    // Funciones para detalles
    validaFechasServicioUsuario() {
      if (
        this.form.detalles.fecha_ini_app_user &&
        this.form.detalles.hora_ini_app_user &&
        this.form.detalles.fecha_fin_app_user &&
        this.form.detalles.hora_fin_app_user
      ) {
        const fecha_menor = new Date(
          `${this.form.detalles.fecha_ini_app_user} ${this.form.detalles.hora_ini_app_user}`
        );
        const fecha_mayor = new Date(
          `${this.form.detalles.fecha_fin_app_user} ${this.form.detalles.hora_fin_app_user}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.detalles.fecha_fin_app_user = null;
          this.form.detalles.hora_fin_app_user = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio del servicio del usuario no puede ser mayor a la fecha fin del servicio.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    validaFechasServicioConductor() {
      if (
        this.form.detalles.fecha_ini_app_conductor &&
        this.form.detalles.hora_ini_app_conductor &&
        this.form.detalles.fecha_fin_app_conductor &&
        this.form.detalles.hora_fin_app_conductor
      ) {
        const fecha_menor = new Date(
          `${this.form.detalles.fecha_ini_app_conductor} ${this.form.detalles.hora_ini_app_conductor}`
        );
        const fecha_mayor = new Date(
          `${this.form.detalles.fecha_fin_app_conductor} ${this.form.detalles.hora_fin_app_conductor}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.detalles.fecha_fin_app_conductor = null;
          this.form.detalles.hora_fin_app_conductor = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio del servicio del conductor no puede ser mayor a la fecha fin del servicio.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    // Funciones para fechas
    validaFechasViaje() {
      if (
        this.form.fechas.fecha_ini &&
        this.form.fechas.hora_ini &&
        this.form.fechas.fecha_fin &&
        this.form.fechas.hora_fin
      ) {
        const fecha_menor = new Date(
          `${this.form.fechas.fecha_ini} ${this.form.fechas.hora_ini}`
        );
        const fecha_mayor = new Date(
          `${this.form.fechas.fecha_fin} ${this.form.fechas.hora_fin}`
        );

        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fechas.fecha_fin = null;
          this.form.fechas.hora_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicio no puede ser mayor a la fecha fin..`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fechas.hora_fin) {
        if (this.form.fechas.hora_fin >= "20:00:00") {
          this.$swal({
            text:
              "Tenga en cuenta que el recorrido de FRONTERA va hasta las 20:00.!!",
            icon: "info",
            confirmButtonText: "Aceptar!",
          });
        }
      }
    },

    back() {
      return this.$router.replace("/Tep/ViajesInternos");
    },
  },

  async mounted() {
    this.cargando = true;
    await this.init();
    this.cargando = false;
  },
};
</script>
